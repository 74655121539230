import './App.css';
import axios from 'axios';
import Select from 'react-select';
import { useState } from 'react';

function App() {
  const [data, setData] = useState(null);
  const [timer, setTimer] = useState(null);

  const options = [
    { value: 'DouLiu', label: '斗六棒球場' },
    { value: 'Tainan', label: '台南棒球場' },
    { value: 'Tianmu', label: '天母棒球場' },
    { value: 'XinZhuang', label: '新莊棒球場' },
  ];

  const fetchData = (event) => {
    axios.get('https://karmazone-4a7ed-default-rtdb.asia-southeast1.firebasedatabase.app/' + event.value + '.json?')
      .then(res => {
        let resData = {
          "Clip_Id": res.data.Clip_Id,
          "VeloRel": res.data['Velo-rel'],
          "Date": res.data.Date,
          "E_Velo": res.data.E_Velo,
          "KZone_Y": res.data.KZone_Y,
          "KZone_Z": res.data.KZone_Z
        }
        setData(resData)
      })
  };

  const handleChange = (event) => {
    clearInterval(timer)
    const interval = setInterval(
      () => {
        fetchData(event)
      }, 1000 //毫秒
    )
    setTimer(interval)
  };

  // const handleClick = () => {
  //   clearInterval(timer)
  // }

  return (
    <>
      <Select placeholder='請選擇球場' options={options} onChange={(event) => { handleChange(event) }} />
      {/* <button type="button" class="btn btn-secondary" onClick={handleClick}>Reset</button> */}
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Clip_Id</th>
            <th scope="col">Velo-rel</th>
            <th scope="col">日期</th>
            <th scope="col">進壘點X</th>
            <th scope="col">進壘點Y</th>
            <th scope="col">擊球初速</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {data === null ? (
              <>
                <td>null</td>
                <td>null</td>
                <td>null</td>
                <td>null</td>
                <td>null</td>
                <td>null</td>
              </>
            ) : (
              <>
                <td>{data.Clip_Id}</td>
                <td>{data.VeloRel}</td>
                <td>{data.Date}</td>
                <td>{data.KZone_Y}</td>
                <td>{data.KZone_Z}</td>
                <td>{data.E_Velo}</td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default App;
